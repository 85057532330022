import {LinkIcon} from "@heroicons/react/20/solid";
import {useEffect, useState} from "react";

import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum'
import {
    configureChains,
    createConfig, serialize,
    useAccount,
    useContractWrite,
    usePrepareContractWrite,
    usePublicClient, useWaitForTransaction,
    WagmiConfig
} from 'wagmi'
import {arbitrum, bsc, mainnet, polygon} from 'wagmi/chains'
import {Web3Button, Web3Modal} from '@web3modal/react'
import {ContractAbi, ContractAddress} from "./contract-abi";
import SuccessModal from "./components/SuccessModal";
import ErrorModal from "./components/ErrorModal";
import { parseEther } from "viem";
import CardModal from "./CardModal";


const chains = [bsc]
const projectId = '34bf75d6d263fae3aca6d39b296c8ce6'

const {publicClient} = configureChains(chains, [w3mProvider({projectId})])
// @ts-ignore
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({projectId, chains}),
    publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)


function LandingPage() {
    const [purchaseSuccess, setPurchaseSuccess] = useState(null as any);
    const [errorMessage, setErrorMessage] = useState('' as any);
    const [successMessage, setSuccessMessage] = useState('');
    const [mode, setMode] = useState('' as 'METAMASK' | 'TRUST_WALLET');
    const [buyMode, setBuyMode] = useState('CRYPTO' as 'CRYPTO' | 'CARD')
    const [cardModal, setCardModal] = useState(false);
    const [tokens, setTokens] = useState(100);
    const [cardDetails, setCardDetails] = useState({} as any);
    const [loading, setLoading] = useState(false as any);
    const [cardError, setCardError] = useState('');
    const {address: account, isConnected} = useAccount();
    const provider = usePublicClient();
    const {
        config,
        error: prepareError,
        isError: isPrepareError,
        isSuccess: isPrepareSuccess
    } = usePrepareContractWrite({
        address: ContractAddress,
        enabled: account && tokens > 1,
        abi: ContractAbi,
        // @ts-ignore
        args: [(account) || ''],
        value: tokens > 10 ? parseEther((tokens / 3482).toString()) : undefined,
        functionName: 'buyTokens',
    })
    const {data, error: transactionError2, isError,isLoading: isSendingTransaction,  write} = useContractWrite(config)
    console.log(transactionError2?.message);
    const makeRequest = async () => {
        try {
            setCardError('');
            if (!cardDetails.email) {
                setCardError('Email required')
                return;
            }
            if (!cardDetails.card_number) {
                setCardError('Card Number required')
                return;
            }
            if (!cardDetails.name_on_card) {
                setCardError('Name required')
                return;
            }
            if (!cardDetails.expiry_month) {
                setCardError('Expiry Month required')
                return;
            }
            if (!cardDetails.expiry_year) {
                setCardError('Expiry Year required')
                return;
            }
            if (!cardDetails.cvv) {
                setCardError('CVV required')
                return;
            }
            const response = await fetch('/api/hello', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({...cardDetails, 'account_address': account, 'amount': (tokens * 0.3)})
            });
            const r = await response.json();
            setSuccessMessage('true')
        } catch (e) {
            alert('Unable to make payment. Please try again.')
            window.location.reload();
            console.log(e);
            console.log('e');
        }
    };
    const handleSendTransaction = async () => {
        if (!tokens || tokens < 1) {
            setErrorMessage('Minimum 100 token is allowed');
            return;
        }
        try {
            if (write) {
                write();
            }
            // // @ts-ignore
            // const eth = new Eth(provider);
            // // @ts-ignore
            // const contract = new eth.Contract(ContractAbi, ContractAddress);
            // console.log(tokens)
            // console.log('tokens')
            // contract.methods.buyTokens(account).send({
            //     from: account,
            //     value: ethers.utils.parseEther((tokens / 1400) + '')
            // })
            //     .then(function (receipt: any) {
            //         setPurchaseSuccess(true);
            //     }).catch(function (error: any) {
            //     setErrorMessage('Error while making transaction, Please check wallet/transaction logs and try again')
            // }).finally(function () {
            //     setLoading(false);
            // });
        } catch (error) {
            console.log(error);
            console.log('error');
        }
    };
    return <>

        <Web3Modal projectId={projectId} ethereumClient={ethereumClient}/>

        {
            cardModal && <CardModal onClose={()=>setCardModal(false)}/>
        }
        {
            purchaseSuccess && <SuccessModal/>
        }

        {
            (errorMessage ||transactionError2?.message) && <ErrorModal onClose={() => setErrorMessage('')} message={(errorMessage ||transactionError2?.message)}/>
        }
        <main className={'flex flex-col items-center justify-center h-screen'}>
            <div className={'rounded-lg bg-white p-4 w-[450px] max-w-[96vw] shadow-2xl max-h-[98vh] overflow-y-auto'}>
                {
                    successMessage ? <>
                        <div className={'border-green-900 border bg-green-100 p-3 rounded-lg'}>
                            <div>
                                Thank you for supporting Tangi. We will need you to furnish us with your wallet
                                address if you did not enter it along with your payment details. We need your wallet
                                address in order to deliver your tokens. If you require assistance setting up your
                                wallet, receiving your tokens, or if you need help with anything else, please
                                contact us on admin@gettangi.com. We always respond. We endeavour to reply to all
                                correspondence within 48 hours.
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                window.location.href = 'https://gettangi.com/';
                            }}
                            className={'mt-3 text-center bg-indigo-500 text-white px-6 py-2 rounded cursor-pointer'}>
                            Go To Website
                        </div>
                    </> : <>
                        <div className={'text-lg border-b pb-2'}>
                            Buy <span className={'font-bold'}>TNG</span> Tokens
                        </div>

                        <div className={'flex space-x-2 mb-4 pt-4'}>
                            <div onClick={() => setBuyMode('CRYPTO')}
                                 className={`flex-1 text-center cursor-pointer border border-indigo-500 rounded px-1 py-2 ${buyMode === 'CRYPTO' ? 'bg-indigo-500 text-white' : ''}`}>
                                Buy Using Crypto
                            </div>
                            <div onClick={() => setCardModal(true)}
                                 className={`flex-1 text-center cursor-pointer border border-indigo-500 rounded  px-1 py-2 ${buyMode !== 'CRYPTO' ? 'bg-indigo-500 text-white' : ''}`}>
                                Buy Using Credit Card
                            </div>
                        </div>
                        {
                            buyMode === 'CRYPTO' ? <> <>
                                {
                                    !account ? <>
                                            <div className={'mt-4'}>
                                                Connect Wallet
                                            </div>
                                            <div className={'w-full flex justify-center items-center mt-2'}>
                                                <Web3Button/>
                                            </div>
                                        </> :
                                        <>
                                            {/*<div className={'mb-2 mt-4'}>*/}
                                            {/*    <span*/}
                                            {/*        className={'bg-indigo-500 rounded-md p-2 text-xs text-white'}>Wallet Connected</span>*/}
                                            {/*</div>*/}
                                            <div className={'flex space-x-1 mt-3 items-center'}>
                                                <div
                                                    className={'animate-ping  w-2 h-2 rounded-full bg-indigo-500'}/>
                                                <span className={'text-xs flex-1'}>
                                                     <Web3Button/>
                                                </span>
                                                {/*<div className={'bg-red-700 rounded-full p-1 cursor-pointer '}*/}
                                                {/*     onClick={handleDisconnect}>*/}
                                                {/*    <XMarkIcon className={'text-white h-3 w-3'}/>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div>
                                                <div className={'mt-4'}>
                                                    <label htmlFor="price"
                                                           className="block text-sm font-medium text-gray-700">
                                                        Number of TNG tokens to purchase (min: 100)
                                                    </label>
                                                    <div className={'flex space-x-2'}>
                                                        <div className="relative mt-1 rounded-md shadow-sm flex-1">
                                                            <input
                                                                type="number"
                                                                name="tokens"
                                                                id="tokens"
                                                                value={tokens}
                                                                onChange={(e) => setTokens(parseInt(e.target.value))}
                                                                className="border bg-white block w-full rounded-md border-gray-300 pl-4 py-4 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                placeholder="100"
                                                                aria-describedby="price-currency"
                                                            />
                                                            <div
                                                                className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                              <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                TNG
                                              </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {
                                                        isSendingTransaction ?
                                                            <div
                                                                className={'p-3 mt-3 bg-indigo-50 border border-indigo-600'}>
                                                                Loading...
                                                            </div> : (
                                                                prepareError ? <div>
                                                                        Insufficient Balance
                                                                    </div>
                                                                    : <div
                                                                        onClick={handleSendTransaction}
                                                                        className={'mt-3 text-center bg-indigo-500 text-white px-6 py-2 rounded cursor-pointer'}>
                                                                        Buy
                                                                    </div>
                                                            )
                                                    }


                                                </div>
                                            </div>
                                        </>
                                }
                            </>
                            </> : <div>
                                <div className="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
                                    <div className="col-span-4">
                                        <label htmlFor="card-number"
                                               className="block text-sm font-medium text-gray-700">
                                            Wallet Address (Optional)
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                id="wallet_address"
                                                name="wallet_address"
                                                value={cardDetails['wallet_address']}
                                                onChange={(e) => {
                                                    setCardDetails({
                                                        ...cardDetails,
                                                        wallet_address: e.target.value,
                                                    })
                                                }}
                                                autoComplete="cc-email"
                                                className="px-3 block w-full rounded-md border-gray-400 border bg-white py-2  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-4">
                                        <label htmlFor="price"
                                               className="block text-sm font-medium text-gray-700">
                                            Number of TNG tokens to purchase (min: 100)
                                        </label>
                                        <div className={'flex space-x-2'}>
                                            <div className="relative mt-1 rounded-md shadow-sm flex-1">
                                                <input
                                                    type="number"
                                                    name="tokens"
                                                    id="tokens"
                                                    value={tokens}
                                                    onChange={(e) => setTokens(parseInt(e.target.value))}
                                                    className="border bg-white block w-full rounded-md border-gray-300 pl-4 py-4 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    placeholder="100"
                                                    aria-describedby="price-currency"
                                                />
                                                <div
                                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                              <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                TNG
                                              </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-span-4">
                                        <label htmlFor="card-number"
                                               className="block text-sm font-medium text-gray-700">
                                            Email
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="email"
                                                id="email"
                                                required={true}
                                                name="email"
                                                value={cardDetails['email']}
                                                onChange={(e) => {
                                                    setCardDetails({
                                                        ...cardDetails,
                                                        email: e.target.value,
                                                    })
                                                }}
                                                autoComplete="cc-email"
                                                className="px-3 block w-full rounded-md border-gray-400 border bg-white py-2  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-4">
                                        <label htmlFor="card-number"
                                               className="block text-sm font-medium text-gray-700">
                                            Card number
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="number"
                                                id="card-number"
                                                name="card-number"
                                                value={cardDetails['card_number']}
                                                onChange={(e) => {
                                                    setCardDetails({
                                                        ...cardDetails,
                                                        card_number: e.target.value,
                                                    })
                                                }}
                                                autoComplete="cc-number"
                                                className="px-3 block w-full rounded-md border-gray-400 border bg-white py-2  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-4">
                                        <label htmlFor="name-on-card"
                                               className="block text-sm font-medium text-gray-700">
                                            Name on card
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                id="name-on-card"
                                                value={cardDetails['name_on_card']}
                                                onChange={(e) => {
                                                    setCardDetails({
                                                        ...cardDetails,
                                                        name_on_card: e.target.value
                                                    })
                                                }}
                                                name="name-on-card"
                                                autoComplete="cc-name"
                                                className="px-3 block w-full rounded-md border-gray-400 border bg-white py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="expiration-month"
                                               className="block text-sm font-medium text-gray-700">
                                            Expiration Month (MM)
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="number"

                                                name="expiration-month"
                                                id="expiration-month"
                                                value={cardDetails['expiry_month']}
                                                onChange={(e) => {
                                                    setCardDetails({
                                                        ...cardDetails,
                                                        expiry_month: e.target.value
                                                    })
                                                }}
                                                autoComplete="cc-exp"
                                                className="px-3 block w-full rounded-md border-gray-400 border bg-white py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <label htmlFor="expiration-year"
                                               className="block text-sm font-medium text-gray-700">
                                            Expiration Year (YY)
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="number"

                                                name="expiration-year"
                                                value={cardDetails['expiry_year']}
                                                onChange={(e) => {
                                                    setCardDetails({
                                                        ...cardDetails,
                                                        expiry_year: e.target.value
                                                    })
                                                }}
                                                id="expiration-year"
                                                autoComplete="cc-exp"
                                                className="px-3 block w-full rounded-md border-gray-400 border bg-white py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="cvc"
                                               className="block text-sm font-medium text-gray-700">
                                            CVC
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="number"

                                                name="cvc"
                                                value={cardDetails['cvv']}
                                                onChange={(e) => {
                                                    setCardDetails({
                                                        ...cardDetails,
                                                        cvv: e.target.value
                                                    })
                                                }}
                                                id="cvc"
                                                autoComplete="csc"
                                                className="px-3 block w-full rounded-md border-gray-400 border bg-white py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                </div>
                                {
                                    cardError && <div
                                        className={'p-3 border border-rose-500 text text-rose-600 bg-rose-100 rounded-md mt-4'}>
                                        {cardError}
                                    </div>
                                }
                                <div className={'pt-3'}>
                                    <div
                                        onClick={makeRequest}
                                        className={'mt-3 w-full text-center bg-indigo-500 text-white px-6 py-2 rounded cursor-pointer'}>
                                        Buy with ({tokens ? tokens * 0.3 : 'NA'}) USD
                                    </div>
                                </div>
                            </div>
                        }

                    </>
                }
                <div className={'pt-4 flex flex-col  text-blue-700 mt-4'}>
                    <div className={'cursor-pointer border-t items-center py-2 flex space-x-2'} onClick={() => {
                        window.open('https://www.youtube.com/watch?v=lPOzo-ni2is')
                    }}>
                        <LinkIcon className={'w-4 h-4'}/>
                        <div>
                            Purchase Tangi With Metamask On Mobile
                        </div>
                    </div>
                    <div className={'cursor-pointer border-t py-2 flex items-center space-x-2'} onClick={() => {
                        window.open('https://www.youtube.com/watch?v=r6RaCCJ6bJ8')
                    }}>
                        <LinkIcon className={'w-4 h-4'}/>
                        <div>
                            Purchase Tangi With TrustWallet on Desktop
                        </div>
                    </div>
                    <div className={'cursor-pointer border-t py-2 flex items-center  space-x-2'} onClick={() => {
                        window.open('https://www.youtube.com/watch?v=b06VvC59P88')
                    }}>
                        <LinkIcon className={'w-4 h-4'}/>
                        <div>
                            Purchase Tangi With Metamask On Desktop
                        </div>
                    </div>
                </div>
            </div>
        </main>

    </>
}
function HomePage (){
    // @ts-ignore
    return <WagmiConfig config={wagmiConfig}>
        <LandingPage/>
    </WagmiConfig>
}
export default HomePage
