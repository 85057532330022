import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'

export default function CardModal({onClose}: { onClose: any }) {
    let [isOpen, setIsOpen] = useState(true)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        if (!isOpen) {
            onClose();
        }
    }, [isOpen]);
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Pay using Card
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Thank you for joining our project. If you're new in the world of
                                            cryptocurrency, please go through this information before proceeding to buy
                                            Tangi tokens using your credit or debit card. It's important to note that
                                            Visa and Mastercard do not support legal cannabis or most of the
                                            cryptocurrency industry, which impacts our card payment option.
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            To assist you, we've created a tutorial video that explains how to purchase
                                            tokens using your card. You'll need a cryptocurrency wallet on your device,
                                            but don't worry - the video will guide you through the setup process. You'll
                                            then learn how to buy cryptocurrency with your debit or credit card, and
                                            finally, how to exchange it for Tangi tokens on our website. The tutorial
                                            video covers each step slowly and clearly, ensuring that you won't miss any
                                            important details. You can access the video by clicking on the link
                                            provided.
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={()=> {
                                                closeModal();
                                                window.open('https://youtu.be/gOCZ45KOW_s')
                                            }}
                                        >
                                            Go to video
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
